.primary-text {
  color: var(--primary);
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  line-height: 16px;
}

.secondary-text {
  color: var(--tertiary);
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  line-height: 16px;
}

h4 {
  color: var(--primary);
  font-family: 'Roboto', sans-serif;
  font-weight: 700 !important;
}

.center-head-bar {
  display: flex;
  justify-content: space-between;
  padding:  1rem 0 0 0;
  width: 95%;
  align-items: center;
}
.list-header-title {
  font-size: 2rem;
  color: var(--primary);
  font-family: 'Roboto', sans-serif;
  font-weight: 700 !important;
}

.header-end-part {
  display: flex;
  align-items: center;
}
.header-filter-section {
  display: flex;
  margin-right: 2rem;
}

.header-sort-icon {
  font-size: 12px;
  padding-right: 0.5rem;
  color: var(--primary);
  line-height: 16px;
}

.capital {
  text-transform: uppercase;
}

@media only screen and (max-width: 700px) {
  .center-head-bar {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .list-header-title {
    font-size: 1.5rem;
  }

  .header-end-part {
    display: flex;
  }

  .header-filter-section {
    display: flex;
    align-items: center;
  }
}
