@import url('./components/button.css');
@import url('./pages/home.css');
@import url('./components/container.css');
@import url('./components/main-layout.css');
@import url('./components/scroll.css');
@import url('./pages/create-job.css');
@import url('./components/card.css');
@import url('./components/cardListing.css');
@import url('./components/headerButton.css');
@import url('./pages/jobs-list.css');
@import url("./components/action-modal-content.css");

:root  {
  --primary: #727275;
  --secondary: #d27319;
  --tertiary: #ffa820;
  --gray-3: #ababab;
  --gray-4: #e1e1e0;
  --gray-5: #f7f7f7;
  --nav-height: 70px;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
    height: 100vh;
    background-color: var(--gray-5);
    overflow: auto;
}

a {
  text-decoration: none;
}

label {
  color: var(--primary);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.primary-text {
    color: var(--primary);
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    line-height: 16px;
}

.secondary-text {
    color: var(--tertiary);
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    line-height: 16px;
}

h4 {
    color: var(--primary);
    font-family: 'Roboto', sans-serif;
    font-weight: 700 !important;
}


.center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jobListing__layout {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 0 15px 0;
    align-items: center;
    flex: auto;
}

.capital {
    text-transform: uppercase;
}