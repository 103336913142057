.card {
  display: flex;
  justify-content: space-between;
  height: 6rem;
  background: #fdfdfe;
  border: 1px solid #efeff0;
  border-radius: 4px;
  padding: 10px 15px;
  width: 95%;
  margin: 10px;
  cursor: pointer;
}

.leftSide-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex: 0.75;
}

.leftSide-card svg {
  color: var(--tertiary);
  font-size: 25px;
  display: flex;
  justify-content: flex-start;
}

.leftSide-card span {
  color: var(--primary);
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
  /*font-size: 19px;*/
}

.card-footer {
  display: flex;
  align-items: center;
  gap: 0.21em;
}

.card-footer span {
  color: var(--gray-3);
  font-size: 13px;
  line-height: 16px;
}

.card-footer h5 {
  color: var(--gray-3);
  line-height: 16px;
  font-size: 13px;
  font-weight: 600 !important;
}

.rightSide-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 0.2;
}

.edit-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  gap: 0.2rem;
  cursor: pointer;
  color: #1c91fe;
}
.edit-card:hover {
  box-shadow: inset 100px 0 0 0 rgba(35, 125, 211, 0.15);
  border-radius: 4px;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.delete-card {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 3px;
  cursor: pointer;
  color: #ef2728;
}

.delete-card:hover {
  box-shadow: inset 100px 0 0 0 #facecf;
  border-radius: 4px;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
