.white-container {
    width: 35%;
    height: auto;
    background-color: white;
    border: 1px solid var(--gray-4);
    margin: 0 auto;
    padding: 50px;
    text-align: left;
    margin-bottom: 100px;
}
.span-error {
    margin: 2px 0;
    padding: 1px 10px;
    color: #ef2728;
    background: #facecf;
    border-radius: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 550 !important;
    line-height: 15px;
}

.title {
    text-transform: uppercase;
    color: var(--gray-4);
    font-weight: bold;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
}