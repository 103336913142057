.flex-container {
    display: flex;
}

.flex-container-space-between {
    justify-content: space-between;
}

.flex-container-center {
    justify-content: center;
}

.container {
    height: auto;
    overflow-y: auto;
}

.container-main {
    width: 100%
}

.create-job-main-section {
    padding: 30px;
    font-size: 14px;
}