.header-button {
  background-color: #fb911f;
  padding: 0.2rem 0.75rem;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 2px solid #fb911f;
}

.header-button:hover {
  color: #fb911f;
  background-color: white;
  border: 2px solid #fb911f;
  border-radius: 2px;
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
}
