.main-container {
    position: sticky;
    top: 0;
    z-index: 200;
}

.line {
    background-color: var(--secondary);
    height: 2px;
    width: 50px;
    margin-left: -8px;
}

.navigation {
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    align-items: center;
    padding: 20px 0;
}

.logo {
    position: absolute;
    top: 20px;
    left: 30px;
}

.logo-1 {
    font-weight: 400 !important;
}